<template>
<div>
        <loading :active.sync="loading"></loading>
        <!--<link rel="stylesheet" href="https://unpkg.com/@coreui/icons/css/all.min.css">-->
        <div class="row"><div class="col-md-12">
          <b-card style="max-width:860px">
            <div slot="header" data-v-step="1">
            <div class="row">
              <div class="col-md-4">
                <strong>{{ totalMat | pad }} Materiais</strong>
              </div>
              <div class="col-md-8" style="text-align:right;">
                <b-form-input size="xs" style="margin-left:10px;float:right;width:200px;" v-model="keyword" @keyup="procura()" placeholder="Buscar materiais ativos..."></b-form-input>
                <a
                  class="btn btn-link" 
                  href="javascript:;"
                  @click="manutencaoGeral()" >
                  <CIcon name="cil-speedometer" />&nbsp;<small>Manutenção</small>
              </a>
                <a
                  class="btn btn-link" 
                  href="javascript:;"
                  @click="showModalSimples()" >
                  <CIcon name="cil-cloud-upload" />&nbsp;<small>Importar</small>
              </a>
                <download-excel
                  class="btn btn-link" 
                  :data   = "matExcel"
                  name = "materiais.xls"
                   >
                  <CIcon name="cil-cloud-download" />&nbsp;<small>Exportar</small>
              </download-excel>
                <b-button variant="link" size="sm" @click.stop="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
              </div>
            </div>
          </div>
<b-tabs content-class="mt-3" v-model="ativa">
  <b-tab title="Ativos" active>
        <table class="table table-hover">
            <thead>
            <tr>
                <th style="width:50px"></th>
                <th data-v-step="6" style="width:200px">Material</th>
                <th style="width:100px">UnC<br><small>Unidade de Compra</small></th>
                <th style="width:120px">Valor / UnC</th>
                <th style="width:120px">UnP<br><small>Unidade de Produção</small></th>
                <th style="width:50px">Divisor</th>
                <th style="width:50px">Perda</th>
                <th style="width:100px">Valor / UnP</th>
                <th v-html="emusoLbl"></th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="(materialItem, index) in mat" :key="index">
                    <td data-title="Ativo?"><small style="font-size:10px;float:right;margin-top:3px;">{{ index + 1 | pad }}</small>
                      <b-form-checkbox v-if="(mat[index].prd_ID === null)" 
                        v-model="mat[index].mat_active" 
                        switch 
                        @change.native="editar(index, true)"
                        size="sm" 
                      ></b-form-checkbox>
                    </td>
                    <td data-title="Material">
                      <b-form-input :readonly="(mat[index].prd_ID !== null)" :class="(mat[index].prd_ID !== null ? 'amarelo' : 'verde')" @blur.native="editar(index)" v-model="mat[index].mat_name" size="sm"></b-form-input>
                    </td>
                    <td data-title="Unidade de Compra">
                      <b-form-select v-if="(mat[index].prd_ID === null)" style="font-size:10px !important;" class="verde" @change="editar(index, false, true)" v-model="mat[index].mat_unidade_medida" :options="optionsUnidadeMedida" size="sm"></b-form-select>
                    </td>
                    <td data-title="Valor / Un. de Compra'">
                      <b-form-select v-if="(mat[index].prd_ID === null)" style="font-size:10px !important; float:left; width: 30%; background-image:none; border-right:none;" class="verde" @change="editar(index, false, true)" v-model="mat[index].mat_moeda" :options="optionsMoeda" size="sm"></b-form-select>
                      <vue-autonumeric  v-if="(mat[index].prd_ID === null)" class="form-control input-sm verde direita" style="float:left; width: 70%;" @blur.native="editar(index)" :options="decimal" v-model="mat[index].mat_value"></vue-autonumeric>
                    </td>
                    <td data-title="Unidade de Produção">
                      <b-form-select v-if="(mat[index].prd_ID === null)" style="font-size:10px !important; background-image:none;" @change="editar(index, false, true)" @click.native="seletorFilter($event)" class="verde" v-model="mat[index].mat_unidade_ID" :options="optionsUnidade" size="sm"></b-form-select>
                    </td>
                    <td data-title="Divisor">
                      <b-form-input v-if="(mat[index].prd_ID === null)" style="font-size:10px !important;" class="amarelo centro" v-model="mat[index].mat_unidade_conv" size="sm" readonly></b-form-input>
                    </td>
                    <td data-title="Perda">
                      <vue-autonumeric  v-if="(mat[index].prd_ID === null)" style="font-size:10px !important; background-image:none;" class="form-control input-sm verde centro" @blur.native="editar(index)" :options="percentual" v-model="mat[index].mat_perda"></vue-autonumeric>
                    </td>
                    <td data-title="Valor / Un. em Prod.">
                      <b-tooltip v-if="parseInt(mat[index].mat_total_real) === 0" :target="'tooltip-target-'+mat[index].mat_ID" triggers="hover">
                        {{ mat[index].mat_total_real.toFixed(6) }}
                      </b-tooltip>
                      <vue-autonumeric :id="'tooltip-target-'+mat[index].mat_ID" class="form-control input-sm amarelo direita" :options="{ decimalCharacter: ',', digitGroupSeparator: '.', currencySymbol: (mat[index].mat_moeda == 'BRL' ? 'R$' : mat[index].mat_moeda) + ' ', currencySymbolPlacement: 'p', decimalPlaces: 3 }" v-model="mat[index].mat_total" readonly></vue-autonumeric>
                    </td>
                    <td data-title="Em uso?">
                      <div v-if="emUso.find(element => parseInt(element) === mat[index].mat_ID)">🔴</div>
                      <div v-else>⚪️</div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr id="adicionando">
                    <th></th>
                    <th data-v-step="2" data-title="Nome do Material"><b-form-input v-model="matName" size="sm"></b-form-input></th>
                    <th data-v-step="3" data-title="Un. de Compra"><b-form-select v-model="matUnidadeID" :options="optionsUnidadeMedida" size="sm"></b-form-select></th>
                    <th data-v-step="4" data-title="Valor por Un. de Compra'"><vue-autonumeric class="form-control input-sm direita" :options="dinheiro" style="font-size:10px;" v-model="matValue"></vue-autonumeric></th>
                    <th data-v-step="5"><button class="btn btn-success btn-sm" title="OBS: Salve as alterações antes de adicionar novos itens." :disabled="adicionou || indexes.length > 0" @click="adicionar()">Adicionar</button></th>
                    <th colspan="3"></th>
                </tr>
            </tfoot>
        </table>
        <div class="adicionar-foot"><button id="naoesquecadesalvar" class="btn btn-success" title="Clique aqui para salvar as alterações" :disabled="indexes.length === 0" @click="salvartudo()">SALVAR ALTERAÇÕES</button></div>
        <b-tooltip :show="naoesqueca" target="naoesquecadesalvar">
          <p><strong>Não esqueça de salvar!</strong></p>
          <p>Clique aqui para salvar as modificações.</p>
        </b-tooltip>
  </b-tab>
<b-tab title="Inativos">
  <table class="table table-hover">
            <thead>
            <tr>
                <th style="width:10px"></th>
                <th data-v-step="6" style="width:200px">Material</th>
                <th style="width:140px">UnC<br><small>Unidade de Compra</small></th>
                <th style="width:130px">Valor / UnC</th>
                <th style="width:140px">UnP<br><small>Unidade de Produção</small></th>
                <th style="width:35px">Divisor</th>
                <th style="width:35px">Perda</th>
                <th style="width:140px">Valor / UnP</th>
                <th>Em Uso</th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="(materialItem2, index) in matoff" :key="index">
                    <td>
                      <b-form-checkbox
                        v-model="matoff[index].mat_active" 
                        switch 
                        @change.native="reativarItem(matoff[index].mat_ID)"
                        size="sm" 
                      ></b-form-checkbox>
                    </td>
                    <td>
                      <b-form-input class="verde" readonly v-model="matoff[index].mat_name" size="sm"></b-form-input>
                    </td>
                    <td>
                      <b-form-select class="verde" style="font-size:10px !important;" readonly v-model="matoff[index].mat_unidade_medida" :options="optionsUnidadeMedida" size="sm"></b-form-select>
                    </td>
                    <td>
                      <vue-autonumeric class="form-control input-sm verde direita" readonly :options="decimal" v-model="matoff[index].mat_value"></vue-autonumeric>
                    </td>
                    <td>
                      <b-form-select style="font-size:10px !important;" readonly class="verde" v-model="matoff[index].mat_unidade_ID" :options="optionsUnidade" size="sm"></b-form-select>
                    </td>
                    <td>
                      <b-form-input style="font-size:10px !important;" class="amarelo centro" readonly v-model="matoff[index].mat_unidade_conv" size="sm"></b-form-input>
                    </td>
                    <td>
                      <vue-autonumeric class="form-control input-sm verde centro" style="font-size:10px !important; background-image:none;" readonly :options="percentual" v-model="matoff[index].mat_perda"></vue-autonumeric>
                    </td>
                    <td>
                      <vue-autonumeric :id="'tooltip-target-'+matoff[index].mat_ID" class="form-control input-sm amarelo direita" :options="decimal2" v-model="matoff[index].mat_total" readonly></vue-autonumeric>
                      <b-tooltip v-if="parseInt(matoff[index].mat_total_real) === 0" :target="'tooltip-target-'+matoff[index].mat_ID" triggers="hover">
                        {{ matoff[index].mat_total_real.toFixed(6) }}
                      </b-tooltip>
                    </td>
                    <td>
                      <div v-if="emUso.find(element => parseInt(element) === matoff[index].mat_ID)">🔴</div>
                      <div v-else><b-button
                        @click="removerItem(index)" 
                        size="sm" 
                        style="background-color: white;"
                      >❌</b-button></div>
                    </td>
                </tr>
            </tbody>
        </table>
</b-tab>
</b-tabs>

          </b-card>
          <b-modal size="lg" id="ajuda" hide-footer title="Matéria Prima">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/_mj8p2oq6Mk?rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>

          <b-modal size="lg" ref="modal-simples" hide-footer title="Múltiplos materiais">
            <b-alert variant="info" show dismissible>
              <p>Utilize o modelo de planilha abaixo para subir mais de um insumo de uma vez.<br>
              Você pode utilizar o EXCEL para copiar e colar aqui.<br>
              Atenção para a coluna de unidade de compra (UnC). Caso o nome não bata com a tabela de unidades, o insumo não será inserido.</p>
            </b-alert>
            <b-alert variant="danger" v-if="isMobile()" show>
        <p><strong>Desculpe!</strong> Este recurso no momento é incompatível com dispositivos móveis.</p>
      </b-alert>
            <hot-table ref="dataTables" :data="dataTable" :settings="dataTableSet"></hot-table>
            <div class="row">
              <div class="col-md-8">
                <b-button variant="success" :disabled="adicionou" @click="salvaSimplesData()">Salvar planilha</b-button>
                &nbsp;&nbsp;<b-button variant="danger" size="sm" @click="removeSimplesData()">Limpar</b-button>
              </div>
              <div class="col-md-4">
                <b-progress :value="vTbl" :max="maxTbl" show-progress animated></b-progress>
              </div>
            </div>
          </b-modal>

        </div></div>
        <a v-if="isMobile()" href="#adicionando" class="btnfloat">
      <CIcon name="cil-plus" class="my-btnfloat" />
    </a>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'
import { HotTable } from '@handsontable/vue'

export default {
  data () {
    return {
      stepsOptions: {
        labels: {
          buttonSkip: 'Sair da ajuda',
          buttonPrevious: 'Anterior',
          buttonNext: 'Próximo',
          buttonStop: 'OK!'
        }
      },
      steps: [
        {
          target: '[data-v-step="1"]',
          header: {
            title: 'Cadastro de Material',
          },
          content: `Aqui você cadastra os insumos que compõe os seus produtos...`
        },
        {
          target: '[data-v-step="2"]',
          content: 'Para adicionar um novo insumo, digite o nome do material aqui (Ex: Arroz)',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="3"]',
          content: 'Coloque aqui a unidade de compra do insumo (Ex: Pacote de 5 kg).<br>Lembre-se de cadastrar as unidades e conversores no menu <a href="/#/configuracoes/unidades">Configurações > Unidades e Conversores</a>.',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="4"]',
          content: 'Digite o valor unitário do insumo, com base na unidade de compra informada, em reais.',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="5"]',
          content: 'Clique aqui para adicionar e pronto!',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="6"]',
          content: 'Não esqueça de clicar no botão de salvar alterações logo após de editar os itens. O botão de adicionar fica inativo caso você tenha algum item que precisa ser salvo.',
          params: {
            placement: 'bottom'
          }
        }
      ],
      materialItem: [],
      ativa: 0,
      adicionou: false,
      sessao: '',
      loading: false,
      mat: [],
      matFull: [],
      matoff: [],
      matExcel: [],
      matTipoID: 1, // MATERIAL!
      matName: '',
      matUnc: '',
      matValue: 0,
      matUnidadeID: 0,
      optionsTipo: [],
      optionsUnidadeMedida: [],
      optionsUnidade: [],
      optionsMoeda: [
        {value: 'BRL', text: 'R$'},
        {value: 'USD', text: 'US$'},
        {value: 'EUR', text: '€'},
        {value: 'GBP', text: '£'},
        {value: 'JPY', text: '¥'},
        {value: 'CNY', text: '元'},
        {value: 'CAD', text: 'CAD$'}
      ],
      naoesqueca: false,
      matdiv: [],
      matnomede: [],
      matnomepara: [],
      matmedida: [],
      matcombo: [],
      matemuso: [],
      emUso: [],
      emusoLbl: '<img src="/img/loading_col.gif" alt="Em uso" />',
      dinheiro: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 2
      },
      dinheiro2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 3
      },
      percentual: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 2
      },
      decimal: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 2
      },
      decimal2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 3
      },
      keyword: '',
      dataTable: [],
      opcoesMedida: [],
      opcoesMedidaIds: [],
      vTbl: 0,
      maxTbl: 0,
      indexes: [],
      totalMat: 0,
      dataTableSet: {
        colHeaders: ['Insumo', 'UnC', this.$session.get('regras')[0].grp_moeda + ' / UnC'],
        rowHeaders: true,
        minSpareRows: 2,
        width: 700,
        height: 250,
        colWidths: [200, 150, 200],
        columns: [
          {}, {
            type: 'autocomplete',
            source: this.opcoesMedida,
            strict: true,
            allowInvalid: false
          },
          {
            type: 'numeric',
            numericFormat: {
              culture: 'pt-BR',
              pattern: '0,0.00'
            }
          }
        ],
        licenseKey: 'non-commercial-and-evaluation'
      }
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.loading = true
    this.verificaSessao()
  },
  filters: {
    pad: function (n, width, z) {
      z = z || '0'
      n = n + ''
      width = width || '3'
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
    },
    moeda: function (m) {
      return String(m.toFixed(6)).replace('.',',')
    }
  },
  methods: {
    isMobile: function () {
      if (screen.width <= 760) {
        return true;
      }
      else {
        return false;
      }
    },
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if (!this.permissao('TABELAS_MATERIAPRIMA'))
        {
          this.$notify({
            type: 'danger',
            title: 'Sem permissão',
            text: 'Você não possui permissão para acessar esta área.'
          })
          return false
        }
        // Criando o insumo eventual
        this.campo('pro_Material', 'mat_ID', '(mat_unc LIKE FIXO)').then(
          (f) => {
            if (!f) {
              // Nao tem (cria)
              this.inserir('pro_Material', {resource: [ {mat_tipo_ID: 1, mat_name: 'Insumos Eventuais', mat_unc: 'Fixo', mat_value: 1, mat_unidade_ID: 1, mat_active: 1, mat_total: 1, mat_perda: 0} ]}).then(
                (response) => {
                  this.recarregar()
                },
                (response) => {
                  this.recarregar()
                }
              )
            } else {
              this.recarregar()
            }
          },
          (f) => {
            // Nao tem (cria)
            this.inserir('pro_Material', {resource: [ {mat_tipo_ID: 1, mat_name: 'Insumos Eventuais', mat_unc: 'Fixo', mat_value: 1, mat_unidade_ID: 1, mat_active: 1, mat_total: 1, mat_perda: 0} ]}).then(
              (response) => {
                this.recarregar()
              },
              (response) => {
                this.recarregar()
              }
            )
          }
        )
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    emUsoCheck: function (i) {
      return this.campo('pro_Produto_Material', 'pmt_mat_ID', '(pmt_mat_ID = ' + String(i) + ')').then(
        (c) => {
          return (c.length > 0 ? 0 : i)
        },
        (c) => {
          return 0
        }
      )
    },
    procura: function () {
      this.mat = this.matFull
      this.mat = (this.keyword
				? this.mat.filter(item => item.mat_name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.keyword.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) || String(item.mat_value).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.keyword.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) || String(item.mat_total).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(this.keyword.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")))
				: this.mat)
    },
    removerItem: function (i) {
      if (confirm('Remover esse item definitivamente?')) {
        this.remover('pro_Material', 'mat_ID = ' + this.matoff[i].mat_ID).then(
          (t) => {
            this.recarregar()
          },
          (t) => {
            this.recarregar()
          }
        )
      } else {
        return false
      }
    },
    reativarItem: function (i) {
      this.atualizar('pro_Material', {resource: [ {mat_active: 1 } ]}, 'mat_ID = ' + String(i)).then(
        (r) => {
          this.ativa = 0
          this.recarregar()
        },
        (r) => {
          this.recarregar()
        }
      ) 
    },
    salvaSimplesData: function () {
      this.adicionou = true
      this.maxTbl = 0
      this.vTbl = 0
      // Analisando os dados
      if (this.dataTable.length === 0) {
        alert('Tabela vazia')
        this.adicionou = false
        return false
      }
      var prom = []
      var erros = ''
      for (var i = 0; i < this.dataTable.length; i++) {
        if (typeof this.dataTable[i][0] !== 'undefined' && this.dataTable[i][0] !== '' && this.dataTable[i][0] !== null && this.dataTable[i][1] !== null && this.dataTable[i][1] !== '') {
          if (typeof this.opcoesMedidaIds[this.dataTable[i][1]] === 'undefined') {
            erros += 'Linha ' + (i+1) + ': Unidade de compra desconhecida<br>'
          }
          if (isNaN(this.dataTable[i][2])) {
            erros += 'Linha ' + (i+1) + ': Valor inválido<br>'
          }
          this.maxTbl++
        }
      }
      if (erros) {
        this.$swal('Atenção', erros, 'error')
        this.adicionou = false
      } else {
        // Adiciona
        for (var i = 0; i < this.dataTable.length; i++) {
          if (typeof this.dataTable[i][0] !== 'undefined' && this.dataTable[i][0] !== '' && this.dataTable[i][0] !== null && this.dataTable[i][1] !== null && this.dataTable[i][1] !== '') {
            prom.push(this.adicionarMassa(this.dataTable[i][0], this.opcoesMedidaIds[this.dataTable[i][1]], this.dataTable[i][2]))
          }
        }
        Promise.all(prom).then(
          (r) => {
            console.log(r)
            for (var v = 0; v < r.length; v++) {
              if (r[v] === 0) {
                erros += 'Linha ' + (v+1) + ' não foi adicionada<br>'
              } else {
                this.vTbl++
              }
            }
            if (this.vTbl !== this.maxTbl) {
              this.$swal('Importação finalizada!', 'Alguns itens não foram adicionados pois provavelmente já existiam:<div style="overflow:auto;height:100px;"><small>' + erros + '</small></div>', 'warning')
              this.adicionou = false
              this.recarregar()
            } else {
              this.$notify({
                type: 'success',
                title: 'Importação Finalizada',
                text: 'Todos os itens foram adicionados'
              })
              this.adicionou = false
              this.dataTable = []
              this.recarregar()
              this.$refs['modal-simples'].hide()
            }
          },
          (r) => {
            console.log(r)
            alert('Erro ao processar planilha')
            this.adicionou = false
          }
        )
      }
    },
    showModalSimples: function () {
      this.$refs['modal-simples'].show()
    },
    removeSimplesData: function () {
      this.dataTable = []
      this.maxTbl = 0
    },
    recarregarUnidades: function (index) {
      if (this.mat[index].mat_unidade_medida_ant !== this.mat[index].mat_unidade_medida) {
        this.mat[index].mat_unidade_ID = this.mat[index].mat_unidade_medida
      }
      this.mat[index].mat_unidade_medida_ant = this.mat[index].mat_unidade_medida
      this.calculaTotal()
      this.mat[index].mat_tipo_ID = this.matTipoID
      this.mat[index].mat_unidade_medida = this.matmedida[this.matnomede[this.mat[index].mat_unidade_ID]]
      this.mat[index].mat_unidade_conv = this.matdiv[this.mat[index].mat_unidade_ID]
    },
    recarregar: function () {
      this.optionsUnidade = []
      this.optionsTipo = []
      this.mat = []
      this.matoff = []
      this.matmedida = []
      this.optionsUnidadeMedida = []
      this.matdiv = []
      this.matnomede = []
      this.matnomepara = []
      this.opcoesMedida = []
      this.opcoesMedidaIds = []
      this.emusoLbl = '<img src="/img/loading_col.gif" alt="Em uso" />'
      this.listar('pro_Material', 'mat_tipo_ID, mat_name', '(mat_tipo_ID = ' + this.matTipoID + ') AND (mat_unc NOT LIKE Fixo)').then(
        (response) => {
        // this.mat = response
        this.listar('pro_Material_Tipo', 'mtp_ID', 'mtp_active >= 0').then(
          (r) => {
            for(var i = 0; i < r.length; i++) {
              this.optionsTipo.push({ value: r[i].mtp_ID, text: r[i].mtp_name, disabled: !r[i].mtp_active })
            }
            this.listar('pro_Material_Unidade', 'mun_sigla', 'mun_active >= 0').then(
              (r) => {
                for(var i = 0; i < r.length; i++) {
                  if (r[i].mun_name === r[i].mun_sigla) {
                    this.optionsUnidadeMedida.push({ value: r[i].mun_ID, text: r[i].mun_sigla, disabled: !r[i].mun_active })
                    this.optionsUnidade.push({ value: r[i].mun_ID, text: r[i].mun_sigla + ' ➡️ ' + r[i].mun_name, disabled: !r[i].mun_active })
                    this.matmedida[r[i].mun_sigla] = r[i].mun_ID
                    this.opcoesMedida.push(r[i].mun_sigla)
                    this.opcoesMedidaIds[r[i].mun_sigla] = r[i].mun_ID
                  } else {
                    this.optionsUnidade.push({ value: r[i].mun_ID, text: r[i].mun_sigla + ' ➡️ ' + r[i].mun_name, disabled: !r[i].mun_active })
                  }
                  this.matdiv[r[i].mun_ID] = r[i].mun_conv
                  this.matnomede[r[i].mun_ID] = r[i].mun_sigla
                  this.matnomepara[r[i].mun_ID] = r[i].mun_name
                }
                this.dataTableSet.columns[1].source = this.opcoesMedida
                var prom = []
                this.loading = false
                var usoParams = {
                  g: this.$session.get('grupo'),
                  t: this.matTipoID
                }
                this.$http.post(this.URLApp + 'tabelas/emUsoCheck', usoParams).then(
                  (xcot) => {
                    this.totalMat = 0
                    this.emUso = xcot.body
                    for (var z = 0; z < response.length; z++) {
                      if (response[z].mat_active) {
                        this.mat.push({
                          mat_ID: response[z].mat_ID,
                          mat_tipo_ID: response[z].mat_tipo_ID,
                          prd_ID: response[z].prd_ID,
                          mat_name: response[z].mat_name,
                          mat_descr: response[z].mat_descr,
                          mat_photo: response[z].mat_photo,
                          mat_unc: response[z].mat_unc,
                          mat_moeda: response[z].mat_moeda,
                          mat_value: response[z].mat_value,
                          mat_unidade_ID: response[z].mat_unidade_ID,
                          mat_created: response[z].mat_created,
                          mat_updated: response[z].mat_updated,
                          mat_active: response[z].mat_active,
                          mat_vidautil: response[z].mat_vidautil,
                          mat_custoanual: response[z].mat_custoanual,
                          mat_custototal: response[z].mat_custototal,
                          mat_horasuteis: response[z].mat_horasuteis,
                          mat_custohora: response[z].mat_custohora,
                          mat_custominuto: response[z].mat_custominuto,
                          mat_custoopt: response[z].mat_custoopt,
                          mat_perda: response[z].mat_perda,
                          mat_total: response[z].mat_total,
                          mat_total_real: response[z].mat_total,
                          grp_ID: response[z].grp_ID,
                          mat_unidade_medida: this.matmedida[this.matnomede[response[z].mat_unidade_ID]],
                          mat_unidade_medida_ant: this.matmedida[this.matnomede[response[z].mat_unidade_ID]],
                          mat_unidade_conv: this.matdiv[response[z].mat_unidade_ID]
                        })
                        this.totalMat++
                      } else {
                        this.matoff.push({
                          mat_ID: response[z].mat_ID,
                          mat_tipo_ID: response[z].mat_tipo_ID,
                          prd_ID: response[z].prd_ID,
                          mat_name: response[z].mat_name,
                          mat_descr: response[z].mat_descr,
                          mat_photo: response[z].mat_photo,
                          mat_unc: response[z].mat_unc,
                          mat_moeda: response[z].mat_moeda,
                          mat_value: response[z].mat_value,
                          mat_unidade_ID: response[z].mat_unidade_ID,
                          mat_created: response[z].mat_created,
                          mat_updated: response[z].mat_updated,
                          mat_active: response[z].mat_active,
                          mat_vidautil: response[z].mat_vidautil,
                          mat_custoanual: response[z].mat_custoanual,
                          mat_custototal: response[z].mat_custototal,
                          mat_horasuteis: response[z].mat_horasuteis,
                          mat_custohora: response[z].mat_custohora,
                          mat_custominuto: response[z].mat_custominuto,
                          mat_custoopt: response[z].mat_custoopt,
                          mat_perda: response[z].mat_perda,
                          mat_total: response[z].mat_total,
                          mat_total_real: response[z].mat_total,
                          grp_ID: response[z].grp_ID,
                          mat_unidade_medida: this.matmedida[this.matnomede[response[z].mat_unidade_ID]],
                          mat_unidade_medida_ant: this.matmedida[this.matnomede[response[z].mat_unidade_ID]],
                          mat_unidade_conv: this.matdiv[response[z].mat_unidade_ID]
                        })
                      }
                      this.matExcel.push({
                        Ativo: response[z].mat_active ? 'Sim' : 'Não',
                        Nome: response[z].mat_name,
                        UnC: this.matnomede[response[z].mat_unidade_ID],
                        Moeda: response[z].mat_moeda,
                        Valor: response[z].mat_value,
                        UnP: this.matnomepara[response[z].mat_unidade_ID],
                        Conv: this.matdiv[response[z].mat_unidade_ID],
                        Perda: response[z].mat_perda,
                        Total: response[z].mat_total
                      })
                    }
                    this.calculaTotal()
                    this.emusoLbl = 'Em uso'
                  }, (xcot) => {
                    alert('Falha desconhecida ao carregar lista. Recarregue a página e tente novamente. Caso o erro persista, contate o suporte da aplicação.')
                  }
                )
              },
              (r) => {
                this.$notify({
                    type: 'danger',
                    title: 'Dados básicos necessários',
                    text: 'Cadastre unidades de compra antes.'
                })
                this.loading = false
              }
            )
          },
          (r) => {
            this.$notify({
                type: 'danger',
                title: 'Dados básicos necessários',
                text: 'Cadastre tipos de materiais antes.'
            })
            this.loading = false
          }
        )
        }, (response) => {})
    },
    adicionar: function () {
      this.adicionou = true
      if (this.matName === '' || this.matValue === 0 || this.matUnidadeID === 0) {
        this.$swal('Todos os campos são obrigatórios')
        this.adicionou = false
        return false
      }
      // Verifica se já existe
      this.campo('pro_Material', 'mat_ID', '(mat_name LIKE ' + this.matName + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.matName) {
            this.adicionou = false
            this.$swal('Já existe um registro com este nome')
          } else {
            this.adicionou = false
            this.$swal('Erro ao inserir: um registro já possui este nome (' + this.matName + ')')
          }
        }, (response) => {
          // Verifica se já existe (em produtos)
          this.campo('pro_Produto', 'prd_ID', '(prd_name LIKE ' + this.matName + ')').then(
            (rr) => {
              if (typeof rr !== 'undefined' && rr === this.matName) {
                this.adicionou = false
                this.$swal('Já existe um produto ou preparo com este nome')
              } else {
                this.adicionou = false
                this.$swal('Erro ao inserir: já existe um produto ou preparo com este nome (' + this.matName + ')')
              }
            },
            (rr) => {
              // Tudo certo
              this.loading = true
              this.inserir('pro_Material', {resource: [ {mat_tipo_ID: this.matTipoID, mat_name: this.matName, mat_unc: this.matUnc, mat_value: this.matValue, mat_total: this.matValue, mat_unidade_ID: this.matUnidadeID, mat_active: 1, mat_perda: 0} ]}).then(
                (response) => {
                  this.matName = ''
                  this.matValue = 0
                  this.adicionou = false
                  this.recarregar()
                },
                (response) => {
                  this.$swal('Erro ao inserir o registro: ' + JSON.stringify(response))
                  this.loading = false
                  this.adicionou = false
                }
              )
            }
          )
        }
      )
    },
    adicionarMassa: function (matName, matUnidadeID, matValue) {
      return this.campo('pro_Material', 'mat_ID', '(mat_name = ' + matName + ') AND (mat_tipo_ID = ' + this.matTipoID + ')').then(
        (response) => {
          return 0
        }, (response) => {
          if (matName === '' || matUnidadeID === '' || matValue === '' || matValue === 0) {
            return 0
          }
          return this.campo('pro_Produto', 'prd_ID', '(prd_name LIKE ' + matName + ')').then(
            (rr) => {
              return 0
            },
            (rr) => {
              return this.inserir('pro_Material', {resource: [ {mat_tipo_ID: this.matTipoID, mat_unc: this.matUnc, mat_name: matName, mat_value: matValue, mat_total: matValue, mat_unidade_ID: matUnidadeID, mat_active: 1, mat_perda: 0} ]}, '', '1').then(
                (r) => {
                  return 1
                },
                (r) => {
                  return 0
                }
              )
            }
          )
        }
      )
    },
    manutencaoGeral: function () {
      if (confirm('Esta ação faz uma checagem geral dos valores e conversores da tabela. Isso pode levar alguns minutos. Continuar?')) {
        this.indexes = []
        for (var i = 0; i < this.totalMat; i++) {
          this.indexes.push(i)
        }
        this.$http.get(this.URLApp + 'tabelas/manutencaoGeral/' + this.$session.get('grupo')).then(
          (c) => {
            this.salvartudo()
          },
          (c) => {
            this.salvartudo()
          }
        )
      } else {
        return false
      }
    },
    salvartudo: function () {
      this.loading = true
      if (this.indexes.length === 0) {
        this.loading = false
        this.naoesqueca = false
        this.$swal('Não há nada a ser salvo aqui.')
        return false
      }
      var i = this.indexes.filter((v, i, a) => a.indexOf(v) === i)
      var index = 0
      var p = []
      var ind = []
      for (var idx in i) {
        index = i[idx]
        p.push(this.editarloop(index))
        ind.push(index)
      }
      this.calculaTotal()
      Promise.all(p).then(
        (r) => {
          console.log(r)
          for (var y = 0; y < r.length; y++) {
            if (r[y] === -1) {
              this.loading = false
              this.$swal('Falha ao salvar', 'Erro no registro da linha ' + (ind[y] + 1) + ': todos os campos são obrigatórios.', 'error')
              return false
            }
            if (r[y] === -2) {
              this.loading = false
              this.$swal('Falha ao salvar', 'Erro no registro na linha ' + (ind[y] + 1) + ': nome já existe.', 'error')
              return false
            }
            if (r[y] === -3) {
              this.loading = false
              this.$swal('Falha ao salvar', 'Erro no registro na linha ' + (ind[y] + 1) + ': falha ao verificar integridade.', 'error')
              return false
            }
            if (r[y] === -4) {
              this.loading = false
              this.$swal('Falha ao salvar', 'Erro no registro na linha ' + (ind[y] + 1) + ': falha ao atualizar. Recarregue a página.', 'error')
              return false
            }
          }
          this.loading = false
          this.indexes = []
          this.naoesqueca = false
        },
        (r) => {
          this.loading = false
          this.naoesqueca = false
          this.$swal('Falha ao salvar um ou mais registros. Verifique sua conexão.')
          return false
        }
      )
    },
    editar: function (index, force, me) {
      if (!force) {
        this.emusoLbl = '<img src="/img/loading_col.gif" alt="Em uso" />'
        this.loading = false
        if (me) {
          this.recarregarUnidades(index)
        }
        this.indexes.push(index)
        if (this.indexes.length === 1) {
          this.naoesqueca = true
        }
        this.calculaTotal()
        return false
      }
      this.indexes = []
      if (this.mat[index].mat_name === '' || this.mat[index].mat_value === 0 || this.mat[index].mat_unidade_ID === 0) {
        this.$swal('Todos os campos são obrigatórios')
        return false
      }
      this.loading = true
      if (this.mat[index].mat_unidade_medida_ant !== this.mat[index].mat_unidade_medida) {
        this.mat[index].mat_unidade_ID = this.mat[index].mat_unidade_medida
      }
      this.mat[index].mat_unidade_medida_ant = this.mat[index].mat_unidade_medida
      this.calculaTotal()
      this.mat[index].mat_tipo_ID = this.matTipoID
      // Verifica se já existe
      this.campo('pro_Material', 'mat_ID', '(mat_name = ' + this.mat[index].mat_name + ') AND (mat_ID != ' + this.mat[index].mat_ID + ') AND (mat_tipo_ID = ' + this.mat[index].mat_tipo_ID + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.matName) {
            this.loading = false
            this.$swal('Já existe um registro com este nome...')
          } else {
            this.loading = false
            this.$swal('Erro ao atualizar. Já existe um registro com esse nome.')
          }
        }, (response) => {
          // Verifica se já existe (em produtos)
          this.campo('pro_Produto', 'prd_ID', '(prd_name LIKE ' + this.matName + ')').then(
            (rr) => {
              if (typeof rr !== 'undefined' && rr === this.matName) {
                this.loading = false
                this.$swal('Já existe um produto ou preparo com este nome')
              } else {
                this.loading = false
                this.$swal('Erro ao inserir: já existe um produto ou preparo com este nome (' + this.matName + ')')
              }
            },
            (rr) => {
              this.atualizar('pro_Material', {resource: [ {mat_tipo_ID: this.mat[index].mat_tipo_ID, mat_name: this.mat[index].mat_name, 
              mat_unc: this.mat[index].mat_unc, mat_moeda: this.mat[index].mat_moeda, mat_value: this.mat[index].mat_value, mat_perda: this.mat[index].mat_perda, mat_unidade_ID: this.mat[index].mat_unidade_ID, 
              mat_active: this.mat[index].mat_active, mat_vidautil: this.mat[index].mat_vidautil, 
              mat_custoanual: this.mat[index].mat_custoanual, mat_custototal: this.mat[index].mat_custototal, 
              mat_horasuteis: this.mat[index].mat_horasuteis, mat_custohora: this.mat[index].mat_custohora, mat_total: this.mat[index].mat_total} ]}, 'mat_ID = ' + this.mat[index].mat_ID).then(
                (response) => {
                  this.mat[index].mat_unidade_medida = this.matmedida[this.matnomede[this.mat[index].mat_unidade_ID]]
                  this.mat[index].mat_unidade_conv = this.matdiv[this.mat[index].mat_unidade_ID]
                  if (force) {
                    this.loading = false
                    this.recarregar()
                  } else {
                    this.loading = false
                  }
                },
                (response) => {
                  this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
                  this.loading = false
                }
              )
            }
          )
        }
      )
    },
    editarloop: function (index) {
      if (this.mat[index].mat_name === '' || this.mat[index].mat_value === 0 || this.mat[index].mat_unidade_ID === 0) {
        return -1
      }
      if (this.mat[index].mat_unidade_medida_ant !== this.mat[index].mat_unidade_medida) {
        this.mat[index].mat_unidade_ID = this.mat[index].mat_unidade_medida
      }
      this.mat[index].mat_unidade_medida_ant = this.mat[index].mat_unidade_medida
      this.mat[index].mat_tipo_ID = this.matTipoID
      // Verifica se já existe
      return this.campo('pro_Material', 'mat_ID', '(mat_name = ' + this.mat[index].mat_name + ') AND (mat_ID != ' + this.mat[index].mat_ID + ') AND (mat_tipo_ID = ' + this.mat[index].mat_tipo_ID + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.mat[index].mat_name) {
            return -3
          } else {
            return -2
          }
        }, (response) => {
          // Verifica se já existe (em produtos)
          return this.campo('pro_Produto', 'prd_ID', '(prd_name LIKE ' + this.mat[index].mat_name + ')').then(
            (rr) => {
              if (typeof rr !== 'undefined' && rr === this.mat[index].mat_name) {
                return -3
              } else {
                return -2
              }
            },
            (rr) => {
              return this.atualizar('pro_Material', {resource: [ {mat_tipo_ID: this.mat[index].mat_tipo_ID, mat_name: this.mat[index].mat_name, 
              mat_unc: this.mat[index].mat_unc, mat_value: this.mat[index].mat_value, mat_moeda: this.mat[index].mat_moeda, mat_perda: this.mat[index].mat_perda, mat_unidade_ID: this.mat[index].mat_unidade_ID, 
              mat_active: this.mat[index].mat_active, mat_vidautil: this.mat[index].mat_vidautil, 
              mat_custoanual: this.mat[index].mat_custoanual, mat_custototal: this.mat[index].mat_custototal, 
              mat_horasuteis: this.mat[index].mat_horasuteis, mat_custohora: this.mat[index].mat_custohora, mat_total: this.mat[index].mat_total} ]}, 'mat_ID = ' + this.mat[index].mat_ID, '', '1').then(
                (response) => {
                  this.mat[index].mat_unidade_medida = this.matmedida[this.matnomede[this.mat[index].mat_unidade_ID]]
                  this.mat[index].mat_unidade_conv = this.matdiv[this.mat[index].mat_unidade_ID]
                  return response['mat_ID']
                },
                (response) => {
                  return -4
                }
              )
            }
          )
        }
      )
    },
    seletorFilter: function (e) {
      var txt = this.matnomede[e.target.value] + ' ➡️'
      for (var i = 0; i < e.target.length; i++) {
        e.target[i].disabled = false
      }
      for (var i = 0; i < e.target.length; i++) {
        if (e.target[i].innerText.indexOf(txt) < 0) {
          e.target[i].disabled = true
        }
      }
    },
    calculaCustoAnual: function(index) {
      var m = this.mat[index]
      if (m.mat_value === 0) {
        this.mat[index].mat_custototal = 0
      } else {
        this.mat[index].mat_custototal = ((m.mat_value + (m.mat_vidautil * m.mat_custoanual)) / m.mat_vidautil)
      }
    },
    calculaCustoHora: function(index) {
      var m = this.mat[index]
      if (m.mat_custototal === 0) {
        this.mat[index].mat_custohora = 0
      } else {
        this.mat[index].mat_custohora = (m.mat_custototal / m.mat_horasuteis)
      }
    },
    calculaTotal: function() {
      var v = 0
      for (var n = 0; n < this.mat.length; n++) {
        if (this.mat[n].prd_ID === null) {
          // Calcula a perta
          v = this.mat[n].mat_value
          if (!this.mat[n].mat_perda) {
            this.mat[n].mat_perda = 0
          }
          if (this.mat[n].mat_perda > 0) {
            v = (this.mat[n].mat_value / (100 - this.mat[n].mat_perda)) * 100
          }
          this.mat[n].mat_total = (v === 0 ? 0 : (v / this.matdiv[this.mat[n].mat_unidade_ID]))
          if (this.matnomede[this.mat[n].mat_unidade_ID] === 'MINUTO') {
            this.mat[n].mat_total = (this.mat[n].mat_custohora / 60)
          }
          if (this.matnomede[this.mat[n].mat_unidade_ID] === 'HORA') {
            this.mat[n].mat_total = (this.mat[n].mat_custohora)
          }
          this.mat[n].mat_total_real = this.mat[n].mat_total
        }
      }
      this.matFull = this.mat
      this.emusoLbl = 'Em uso'
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    }
  },
  components: {
    Loading,
    VueAutonumeric,
    HotTable
  }
}
</script>